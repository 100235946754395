
import { Flow } from '@/lib/Flow'
import { PaymentInstrumentTypeString, PaymentOption } from '@/lib/types'


export const OK = 200
export const FAILED = 500
export const VALIDATION_ERROR = 400
export const AUTHENTICATION_ERROR = 401
export const CANCELLED = 499

export const GpmEvents = {
    GPMValidationFailed: VALIDATION_ERROR,
    GPMApiAuthenticationFailed: AUTHENTICATION_ERROR,

    GPMProfileEditStarted: OK,

    GPMProfileCreated: OK,
    GPMProfileCreationCancelled: CANCELLED,
    GPMProfileCreationFailed: FAILED,

    GPMReceivableAuthorized: OK,
    GPMReceivableAuthorizeCancelled: CANCELLED,
    GPMReceivableAuthorizeFailed: FAILED,

    GPMReceivableCaptured: OK,
    GPMReceivableCaptureCancelled: CANCELLED,
    GPMReceivableCaptureFailed: FAILED
}

export type GpmEventType = keyof typeof GpmEvents

export type GpmEvent = {
    type: GpmEventType
    statusCode: number,
    lastAction: string,
    flow: Flow | keyof typeof Flow,
    messages: string[],
    inputPayload?: any,
    // Get this from the GPM API call
    resultPayload?: any,
    paymentOptions?: PaymentOption<PaymentInstrumentTypeString>[],
    selectedPaymentOption?: PaymentOption<PaymentInstrumentTypeString>,
}
