import { Errors } from './Errors'
import { Flow } from './Flow'
import {  
    Payload,  
    SessionPayload, 
    TransactionType,    
  } from './types'

export const getAppFlow = (payload: Payload): Flow => {
    const tx = payload.transactionType
    const profileId = payload.profileId
         if(tx == TransactionType.Authorize)
         {
            return Flow.CreateProfileAndAuthorize
         }         
        if(tx == TransactionType.AuthorizeAndCapture)
        {
            return Flow.CreateProfileAuthorizeAndCapture
        }
        return Flow.Unknown        
    }


export const getSessionMetaData = (): SessionPayload | undefined => {
 const payload : any ={}

 const sourceSystem = sessionStorage.getItem("sourceSystem");
 if(sourceSystem != undefined && sourceSystem != null)
 {
    payload.sourceSystem = sourceSystem;
 }
 const sourceSystemIdentifier = sessionStorage.getItem("sourceSystemIdentifier")
 if(sourceSystemIdentifier != undefined && sourceSystemIdentifier != null)
 {
    payload.sourceSystemIdentifier = sourceSystemIdentifier;
 }
 const token = sessionStorage.getItem("token")
 if(token != undefined && token != null)
 {
    payload.token = token;
 }
 const transactionIntentId = sessionStorage.getItem("transactionIntentId")
 if(transactionIntentId != undefined && transactionIntentId != null)
 {
    payload.transactionIntentId = transactionIntentId;
 }
 return payload as SessionPayload
}

export const validateSessionMetaData= (payload: SessionPayload): Errors<SessionPayload> => {
    const result: Errors<SessionPayload> = {}
    if (!payload.sourceSystem)
        result.sourceSystem = requiredMsg('Source system')
    if (!payload.sourceSystemIdentifier)
        result.sourceSystemIdentifier = requiredMsg('Source system identifier')
    if (payload.sourceSystemIdentifier.toLowerCase() == payload.sourceSystem.toLowerCase())
        result.sourceSystemIdentifier = 'Source system identifier should not be the same as Source system'
    if (!payload.token)
        result.token = requiredMsg('OKTA Token')
    if (!payload.transactionIntentId)
        result.transactionIntentId = requiredMsg('Transaction Intent Id')    
    return result
}

const requiredMsg = (thing: string, ...values: string[]) =>
    values.length == 0
        ? `${thing} is required`
        : values.length == 1 
            ? `${thing} is required and should be '${values[0]}'`
            : values.length == 2
                ? `${thing} is required and should be either '${values[0]}' or '${values[1]}'`
                : `${thing} is required and should be one of `
                    + `'${values.slice(0, -1).join("', '")}' or '${values.pop()}'`


