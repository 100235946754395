
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { 
    AuthorizeAndCaptureReceivableRequest,
    AuthorizeAndCaptureReceivableResponse,
    InitializeVendorUIRequest,
    InitializeVendorUIResponse,
    PaymentInstrumentType,
    PaymentOption,
    Profile,
    RequestOrigin,
    SaveVendorProfileRequest,
    SourceSystemContext,
    TransactionType,
    pitToString
} from '@/lib/types'
import { getLogger } from '@/lib/log'

const logger = getLogger(process.env.VUE_APP_ENABLE_DEBUG_LOGGING)
const debugLog = logger.debug


// eslint-disable-next-line
export namespace Request {
    export type GetProfile = {
        sourceSystem: string,
        sourceSystemIdentifier: string,
        sourceSystemContext: SourceSystemContext,
        profileId: string
    }

    export type GetPaymentOptions = {
        sourceSystem: string,
        sourceSystemProgramIdentifier: string,
        requestOrigin: RequestOrigin
    }

    export type GetPaymentDetails = {
        sourceSystem: string,
        sourceSystemIdentifier: string,
        sourceSystemContext: SourceSystemContext,
        sourceSystemProgramIdentifier: string,
        preferredCultureCode: string,
        requestOrigin: RequestOrigin,
        transactionType: TransactionType,
        amount?: number
        vendor: string,
        paymentInstrumentType: PaymentInstrumentType
    }

    export type SaveVendorProfile = {
        sourceSystem: string,
        sourceSystemIdentifier: string,
        sourceSystemContext: SourceSystemContext
    } & SaveVendorProfileRequest

    export type AuthorizeAndCapture = {
        sourceSystem: string,
        sourceSystemIdentifier: string,
        sourceSystemContext: SourceSystemContext,
        profileId: string,
        amount: {
            currencyCode: string,
            value: number
        },
        sourceSystemProgramIdentifier: string,
        sourceSystemTransactionId: string
    }
}

export class ProfileApi {
    private instance: AxiosInstance
    private _subscriptionKey: string
    private _token: string

    constructor(subscriptionKey: string, token: string, baseUri: string) {
        this._subscriptionKey = subscriptionKey
        this._token = token

        this.instance = axios.create({
            baseURL: baseUri
        })
        this.instance.interceptors.request.use(
            config => config,
            error => Promise.reject(error))
        this.instance.interceptors.response.use(
            config => config,
            error => Promise.reject(error))
    }

    public async getProfile(request: Request.GetProfile): Promise<Profile> {
        return await this.get<Profile>(
            `/profiles/${request.sourceSystem}`
            + `/${SourceSystemContext[request.sourceSystemContext]}`
            + `/${request.sourceSystemIdentifier}`
            + `/${request.profileId}`)
    }

    public async getPaymentOptions(
        request: Request.GetPaymentOptions
    ): Promise<PaymentOption[]> {
        return await this.get<PaymentOption[]>(
            `/programs/${request.sourceSystem}`
            + `/${request.sourceSystemProgramIdentifier}`
            + `/${RequestOrigin[request.requestOrigin]}`
            + '/profile-options')
    }

    public async getPaymentDetails(
        request: Request.GetPaymentDetails
    ): Promise<InitializeVendorUIResponse> {
        return await this.post<InitializeVendorUIRequest, InitializeVendorUIResponse>(
            `/ui/${request.sourceSystem}`
            + `/${SourceSystemContext[request.sourceSystemContext]}`
            + `/${request.sourceSystemIdentifier}`
            + `/${request.vendor}`
            + '/init',
            {
                extensions: {},
                paymentInstrumentType: pitToString(request.paymentInstrumentType),
                preferredCultureCode: request.preferredCultureCode,
                programIdentifier: request.sourceSystemProgramIdentifier,
                requestOrigin: request.requestOrigin,
                totalAmount: request.amount ?? 0,
                transactionType: TransactionType[request.transactionType] as 
                    keyof typeof TransactionType
            })
    }

    public async saveVendorProfile(
        request: Request.SaveVendorProfile
    ): Promise<Profile> {
        const { 
            sourceSystem,
            sourceSystemIdentifier,
            sourceSystemContext,
            ...body
        } = request
        return await this.post<SaveVendorProfileRequest, Profile>(
            `/profiles/vendor`
            + `/${sourceSystem}`
            + `/${SourceSystemContext[sourceSystemContext]}`
            + `/${sourceSystemIdentifier}`,
            body)
    }

    public async authorizeAndCapture(
        request: Request.AuthorizeAndCapture
    ): Promise<AuthorizeAndCaptureReceivableResponse> {
        const {
            sourceSystem,
            sourceSystemIdentifier,
            sourceSystemContext,
            profileId,
            ...body
        } = request
        return await this.post<
            AuthorizeAndCaptureReceivableRequest,
            AuthorizeAndCaptureReceivableResponse>(
            `receivables/${sourceSystem}`
            + `/${SourceSystemContext[sourceSystemContext]}`
            + `/${sourceSystemIdentifier}`
            + `/authorizeandcapture/${profileId}`,
            {
                ...body
            })
    }

    private async get<TResult>(
        uri: string,
        config?: AxiosRequestConfig
    ): Promise<TResult> {
        config = await this.addAuthHeader(config)
        debugLog('@@ API GET to', uri)
        try{
            const response = await this.instance.get<TResult>(uri, config)
            debugLog('@@ Success', response.data)
            return response.data as TResult
        }
        catch (err){
            debugLog('@@ Failure', err)
            throw err
        }
    }

    private async post<TRequest, TResult>(
        uri: string,
        request: TRequest,
        config?: AxiosRequestConfig
    ): Promise<TResult> {
        config = await this.addAuthHeader(config)
        debugLog('@@ API POST to', uri)
        try{
            const response = await this.instance.post<TResult>(uri, request, config);
            debugLog('@@ Success', response.data)
            return response.data as TResult
        }
        catch (err){
            debugLog('@@ Failure', err)
            throw err
        }
    }

    private async addAuthHeader(config?: any) {
        config = { headers: this.getAuthHeader() }
        return config
    }

    private getAuthHeader() {
        return {
            'Ocp-Apim-Subscription-key': this._subscriptionKey,
            'Authorization': `Bearer ${this._token}`
        }
    }
}
