
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { viewModel } from '@/lib/AppService'

@Component
export default class VendorFrame extends Vue {
    @Prop validationErrors: string[] = []
    vm = viewModel

    get url() { return this.vm.vendorData?.url }       
}
