
import { Errors } from './Errors'
import { Flow } from './Flow'
import {
  SourceSystemContext,
  Payload,
  RequestOrigin,
  TransactionType,
  currencyCodeValues,
  currencyDecimals
} from './types'

export const getFlow = (payload: Payload): Flow => {
    const tx = payload.transactionType
    const profileId = payload.profileId

    return tx ==          
       TransactionType.Authorize
            ? !profileId
                ? Flow.CreateProfileAndAuthorize
                : Flow.Unknown
            : tx == TransactionType.AuthorizeAndCapture
                ? !profileId
                    ? Flow.CreateProfileAuthorizeAndCapture
                    : Flow.Unknown
                : Flow.Unknown
}

export const getWindowPayload = (): Payload | undefined => {
    const payload = (window as any).gpmPayload
    if (!payload || !(typeof payload == 'object'))
        return undefined

    if (payload.requestOrigin == 1 || payload.requestOrigin == 'WebSelfServe')
        payload.requestOrigin = RequestOrigin.WebSelfServe
    else if (payload.requestOrigin == 2 || payload.requestOrigin == 'AgentAssisted')
        payload.requestOrigin = RequestOrigin.AgentAssisted
    else
        payload.requestOrigin = RequestOrigin.None

    if (payload.sourceSystemContext == 1 || payload.sourceSystemContext == 'Policy')
        payload.sourceSystemContext = SourceSystemContext.Policy
    else if (payload.sourceSystemContext == 2 || payload.sourceSystemContext == 'Claim')
        payload.sourceSystemContext = SourceSystemContext.Claim
    else
        payload.sourceSystemContext = SourceSystemContext.None

    if (payload.transactionType == 1 || payload.transactionType == 'Verify')
        payload.transactionType = TransactionType.Verify
    else if (payload.transactionType == 2 || payload.transactionType == 'Authorize')
        payload.transactionType = TransactionType.Authorize
    else if (payload.transactionType == 3
        || payload.transactionType == 'AuthorizeAndCapture')
        payload.transactionType = TransactionType.AuthorizeAndCapture
    else
        payload.transactionType = TransactionType.None

    return payload as Payload
}

export const setWindowPayload = (payload: Payload) => {
    (window as any).gpmPayload = payload
}

export const validatePayload = (payload: Payload): Errors<Payload> => {
    const result: Errors<Payload> = {}

    const shouldAuthorize = [
        TransactionType.Authorize]
        .includes(payload.transactionType)

    const shouldAuthorizeAndCapture = [        
        TransactionType.AuthorizeAndCapture]
        .includes(payload.transactionType)

    if (!payload.sourceSystem)
        result.sourceSystem = requiredMsg('Source system')
    if (!payload.sourceSystemIdentifier)
        result.sourceSystemIdentifier = requiredMsg('Source system identifier')
    if (payload.sourceSystemIdentifier.toLowerCase() == payload.sourceSystem.toLowerCase())
        result.sourceSystemIdentifier = 'Source system identifier should not be the same as Source system'
    if (!payload.sourceSystemProgramIdentifier)
        result.sourceSystemProgramIdentifier = requiredMsg('Source system program identifier')
    if (payload.requestOrigin == RequestOrigin.None)
        result.requestOrigin = requiredMsg('Request origin', 'WebSelfServe', 'AgentAssisted')
    if (payload.sourceSystemContext == SourceSystemContext.None)
        result.sourceSystemContext = requiredMsg('Source system context', 'Policy', 'Claim')
    if (!payload.token)
        result.token = requiredMsg('OKTA Token')
    if (payload.preferredCultureCode != 'en-US')
        result.preferredCultureCode = requiredMsg('Preferred cultured code', 'en-US')
    if (payload.transactionType == TransactionType.None)
        result.transactionType = 'Transaction type is required and should be one of'
            + ' Verify, Authorize, or AuthorizeAndCapture'    
    if (shouldAuthorizeAndCapture && !payload.amount)
        result.amount = authAndCaptureMsg('Amount is required')
    if (shouldAuthorizeAndCapture && payload.amount && payload.amount <= 0)
        result.amount = authAndCaptureMsg('Amount should be greater than 0')
    if ((shouldAuthorize || shouldAuthorizeAndCapture) && !payload.currencyCode)
        result.currencyCode = authAndCaptureMsg('Currency code is required')
    if ((shouldAuthorize || shouldAuthorizeAndCapture) && payload.currencyCode && !currencyCodeValues.includes(payload.currencyCode))
        result.currencyCode = authAndCaptureMsg(requiredMsg('Currency code', ...currencyCodeValues))
    if (shouldAuthorizeAndCapture
        && payload.currencyCode
        && currencyCodeValues.includes(payload.currencyCode)
        && payload.amount
        && payload.amount > 0
        && !validateDecimals(payload.amount, currencyDecimals[payload.currencyCode])) {
        const maxDecimals = currencyDecimals[payload.currencyCode]
        result.amount = maxDecimals == 0
            ? `Amount in '${payload.currencyCode}' should have no decimals`
            : `Amount in '${payload.currencyCode}' should have no more than `
                + `${maxDecimals} decimals`
    }
    if ((shouldAuthorize || shouldAuthorizeAndCapture) && !payload.sourceSystemTransactionId)
        result.sourceSystemTransactionId = authAndCaptureMsg(
            'Source system transaction ID is required')
    return result
}

const requiredMsg = (thing: string, ...values: string[]) =>
    values.length == 0
        ? `${thing} is required`
        : values.length == 1 
            ? `${thing} is required and should be '${values[0]}'`
            : values.length == 2
                ? `${thing} is required and should be either '${values[0]}' or '${values[1]}'`
                : `${thing} is required and should be one of `
                    + `'${values.slice(0, -1).join("', '")}' or '${values.pop()}'`

const authAndCaptureMsg = (msg: string) =>
    `${msg} when transactionType is 'Authorize' or 'AuthorizeAndCapture'`

const validateDecimals = (value: number, decimals: number): boolean =>
    Number.parseFloat(value.toFixed(decimals)) >= value
