
import { createApp } from 'vue'

import '@/assets/styles.css'
import App from '@/App.vue'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faBank,
    faCreditCard,
    faMoneyBill1,
    faPen
} from '@fortawesome/free-solid-svg-icons'


library.add(
    faBank,
    faCreditCard,
    faMoneyBill1,
    faPen
)

createApp(App)
.component('fa-icon', FontAwesomeIcon)
.mount('#app')
 



