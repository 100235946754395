
export type Errors<T extends object> = {
    [name in keyof(T)]?: string
}

export const hasErrors = <T extends object>(errors: Errors<T>): boolean =>
    Object.values(errors).some(Boolean)

export const getErrors = <T extends object>(errors: Errors<T>): string[] =>
    Object.values(errors).filter(Boolean).map(x => x as string)
