
import { Component, Emit, Vue } from 'vue-facing-decorator'
import { Flow } from '@/lib/Flow'
import { viewModel } from '@/lib/AppService'
@Component
export default class PaymentSuccess extends Vue {
    vm = viewModel
    get linksMessage(): string {
        switch (this.vm.flow) {
            case Flow.CreateProfile:
            case Flow.CreateProfileAndAuthorize:
                return 'Your Payment Method Was Saved. If you have any questions or concerns please call,' + this.vm.transactionIntent?.sourceSystemSupportPhoneNumber +' for more information.'     
            case Flow.CreateProfileAuthorizeAndCapture:
                return 'Thank you for making your premium payment. You will see a transaction post to your chosen method of payment with the description. If you have any question or concerns please call,' + this.vm.transactionIntent?.sourceSystemSupportPhoneNumber + ' for more information '
        }
        return 'Invalid flow'
    }
}
