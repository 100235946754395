
import { action, computed, observable, makeObservable } from 'mobx'
import { useMobX } from 'vue-mobx-finegrained'
import { AxiosError } from 'axios'
import { getErrors, hasErrors } from '@/lib/Errors'
import {
    AuthorizeAndCaptureReceivableResponse,
    SourceSystemContext,
    InitializeVendorUIResponse,
    Payload,
    PaymentInstrumentType,
    PaymentOption,
    pitFromString,
    pitToString,
    Profile,
    RequestOrigin,
    SaveVendorProfileRequest,
    TransactionType,
    SessionPayload,
    ApplePayPaymentResponse,
    ApplePayPaymentBundle
} from '@/lib/types'
import { Flow } from '@/lib/Flow'
import { State } from '@/lib/State'
import { getFlow, getWindowPayload, validatePayload } from '@/lib/GpmPayload'
import { getAppFlow, getSessionMetaData, validateSessionMetaData } from '@/lib/AppSession'
import {
    GpmEventType,
    GpmEvent,
    GpmEvents,
    VALIDATION_ERROR,
    CANCELLED,
    FAILED
} from '@/lib/GpmEvent'
import { ProfileApi, Request } from '@/lib/ProfileApi'
import { PaymentsApi, Request as PaymentRequest, Response as PaymentResponse } from '@/lib/PaymentsApi'
import { getLogger } from '@/lib/log'

const logger = getLogger(process.env.VUE_APP_ENABLE_DEBUG_LOGGING)
const log = logger.write
const debugLog = logger.debug

export interface IAppService {
    state: State,
    restoreState: State,
    loading: boolean,
    paymentSuccess: boolean,
    lastAction: string,
    payload?: Payload,
    sessionPayload?: SessionPayload,
    subscriptionKey: string,
    baseUri: string,
    profile?: Profile,
    paymentOptions?: PaymentOption[],
    selectedPaymentOption?: PaymentOption,
    vendorOrigin: string,
    vendorData?: InitializeVendorUIResponse,
    receivableCapture?: AuthorizeAndCaptureReceivableResponse,
    done: boolean,
    flow: Flow,
    isEditFlow: boolean,
    isCreateFlow: boolean,
    isAuthorizeFlow: boolean,
    isApplePaySelected: boolean,
    isApplePaySupported: boolean,
    transactionIntent?: PaymentResponse.TransactionIntentResponse,
    paymentDetails?: PaymentResponse.PaymentDetails,
    reset(): void,
    cancel(): void,
    loadPayload(): void,
    fetchProfile(): void,
    fetchPaymentOptions(): void,
    selectPaymentOption(paymentInstrumentType: PaymentInstrumentType): void,
    fetchPaymentOption(): void,
    setVendorUIShown(): void,
    setVendorUICancelled(): void,
    setVendorUIFailed(recoverable: boolean): void,
    saveVendorProfile(data: string): void,
    captureReceivable(): void,
    fetchTransactionIntent(): void,
    saveTransaction(): void,
    validateAppleMerchantSession(url: string): any,
    processApplePayPayment(paymentInstrument:ApplePayPaymentBundle, 
        latitude:string, 
        longitude:string): void
}

export class AppService implements IAppService {
    private _state = State.Start
    private _restoreState = State.Start
    private _flow = Flow.Unknown
    private _loading = false
    private _paymentSuccess = false
    private _lastAction = ''
    private _subscriptionKey = process.env.VUE_APP_GPM_API_SUBSCRIPTION_KEY
    private _baseUri = process.env.VUE_APP_GPM_API_BASE_URI
    private _payload?: Payload
    private _sessionPayload?: SessionPayload
    private _profile?: Profile
    private _paymentOptions?: PaymentOption[]
    private _selectedPaymentOption?: PaymentOption
    private _vendorOrigin = process.env.VUE_APP_CHASE_IFRAME_ORIGIN
    private _vendorData?: InitializeVendorUIResponse
    private _receivableCapture?: AuthorizeAndCaptureReceivableResponse
    private _transactionIntent?: PaymentResponse.TransactionIntentResponse
    private _isApplePaySupported = false;

    observables = {
        _state: observable,
        _restoreState: observable,
        _flow: observable,
        _loading: observable,
        _paymentSuccess: observable, 
        _lastAction: observable,
        _payload: observable,
        _sessionPayload: observable,
        _subscriptionKey: observable,
        _baseUri: observable,
        _profile: observable,
        _paymentOptions: observable,
        _selectedPaymentOption: observable,
        _vendorData: observable,
        _receivableCapture: observable,
        _transactionIntent: observable,
        _isApplePaySupported: observable,
        state: computed,
        restoreState: computed,
        loading: computed,
        paymentSuccess: computed,
        lastAction: computed,
        payload: computed,
        sessionPayload: computed,
        profile: computed,
        subscriptionKey: computed,
        baseUri: computed,
        paymentOptions: computed,
        selectedPaymentOption: computed,
        vendorOrigin: computed,
        vendorData: computed,
        receivableCapture: computed,
        done: computed,
        flow: computed,
        isEditFlow: computed,
        isCreateFlow: computed,
        isAuthorizeFlow: computed,
        isApplePaySelected: computed,
        isApplePaySupported: computed,
        transactionIntent: computed,
        reset: action,
        cancel: action,
        loadPayload: action,
        fetchProfile: action,
        fetchPaymentOptions: action,
        selectPaymentOption: action,
        fetchPaymentOption: action,
        saveVendorProfile: action,
        captureReceivable: action,
        setVendorUIShown: action,
        setVendorUICancelled: action,
        switchToCreateFlow: action,
        handleErrorResponse: action,
        throw: action,
        verifyStateIs: action,
        fetchTransactionIntent: action,
        saveTransaction: action,
        validateAppleMerchantSession: action,
        processApplePayPayment: action
    }

    constructor() {
        makeObservable(this, this.observables)
    }

    get vm(): IAppService {
        return useMobX<AppService>(this, this.observables, { attach: 'vm' })
    }

    get state() { return this._state }
    private set state(value: State) { this._state = value }

    get restoreState() { return this._restoreState }
    private set restoreState(value: State) { this._restoreState = value }

    get transactionIntent() {return this._transactionIntent}
    private set transactionIntent(value: PaymentResponse.TransactionIntentResponse | undefined) {this._transactionIntent = value}

    get loading() { return this._loading }
    private set loading(value: boolean) { this._loading = value }

    get paymentSuccess() { return this._paymentSuccess }
    private set paymentSuccess(value: boolean) { this._paymentSuccess = value }

    get isApplePaySupported() { return this._isApplePaySupported}
    private set isApplePaySupported(value: boolean){ this._isApplePaySupported = value}

    get lastAction() { return this._lastAction }
    private set lastAction(value: string) { this._lastAction = value }

    get payload() { return this._payload }
    private set payload(value: Payload | undefined) { this._payload = value }

    get sessionPayload() { return this._sessionPayload }
    private set sessionPayload(value: SessionPayload | undefined) { this._sessionPayload = value }

    get subscriptionKey() { return this._subscriptionKey }
    get baseUri() { return this._baseUri }

    get profile() { return this._profile }
    private set profile(value: Profile | undefined) { this._profile = value }

    get paymentOptions() { return this._paymentOptions }
    private set paymentOptions(value: PaymentOption[] | undefined) { 
        this._paymentOptions = value
    }

    get selectedPaymentOption() { return this._selectedPaymentOption }
    private set selectedPaymentOption(value: PaymentOption | undefined) {
        this._selectedPaymentOption = value
    }

    get vendorOrigin() { return this._vendorOrigin }

    get vendorData() { return this._vendorData }
    private set vendorData(value: InitializeVendorUIResponse | undefined) {
        this._vendorData = value
    }

    get receivableCapture() { return this._receivableCapture }
    private set receivableCapture(value: AuthorizeAndCaptureReceivableResponse | undefined) {
        this._receivableCapture = value
    }

    get done() { return [State.Completed, State.Failed].includes(this.state) }

    get flow() { return this._flow }
    private set flow(value: Flow) { this._flow = value }
    
    get isEditFlow() { 
        return false;
    }

    get isCreateFlow() {
        return [
            Flow.CreateProfile,
            Flow.CreateProfileAndAuthorize,
            Flow.CreateProfileAuthorizeAndCapture
        ]
        .includes(this.flow)
    }

    get isAuthorizeFlow() {
        return [
            Flow.CreateProfileAndAuthorize,
            Flow.CreateProfileAuthorizeAndCapture            
        ]
        .includes(this.flow)
    }

    get isApplePaySelected() {
        return (this._selectedPaymentOption?.paymentInstrumentType == PaymentInstrumentType.ApplePay)
    }

    private get isCaptureFlow() {
        return [
            Flow.CreateProfileAuthorizeAndCapture           
        ]
        .includes(this.flow)
    }

    reset = () => {
        debugLog('-- AppService: reset')
        this.state = State.Start
        this.loading = false
        this.paymentSuccess = false
        this.lastAction = ''
        this.payload = undefined
        this.paymentOptions = undefined
        this.selectedPaymentOption = undefined
        this.vendorData = undefined
    }

    cancel = () => {
        if (this.restoreState == State.Start){
            this.sendEvent(CANCELLED)
            this.reset()
            return
        }

        this.state = this.restoreState
        this.restoreState = State.Start
        this.switchToEditFlow()
    }

    loadPayload = async () => {
        this.lastAction = 'loadPayload'
        await this.logAction(async () => {
            if (!this.verifyStateIs(State.Start))
                return

            if (!this.subscriptionKey){
                this.throw(VALIDATION_ERROR, 'Subscription key was not found')
                return
            }

            const payload = getWindowPayload()
            const sessionPayload = getSessionMetaData()
            
            if (!payload && !sessionPayload){                
                this.throw(VALIDATION_ERROR, 'Payload was not found')
                return                              
            }

            if(payload)
            {
             const errors = validatePayload(payload as Payload)
             if (hasErrors(errors)){
                const messages = getErrors(errors)
                this.payload = payload
                this.throw(VALIDATION_ERROR, 'Invalid payload', ...messages)
                return
             }

            this.payload = payload
            this.state = State.PayloadValidated
            this.flow = getFlow(this.payload)
            }
            else if(sessionPayload)
            {
             const errors = validateSessionMetaData(sessionPayload)
             if (hasErrors(errors)){
                const messages = getErrors(errors)
                //this.sessionPayload = sessionPayload
                this.throw(VALIDATION_ERROR, 'Invalid payload', ...messages)
                return
             }
             this.sessionPayload = sessionPayload;

             await this.fetchTransactionIntent()
             const payload = this.payload;
             if(payload)
               {
                const errors = validatePayload(payload as Payload)
                if (hasErrors(errors)){
                   const messages = getErrors(errors)
                   this.payload = payload
                   this.throw(VALIDATION_ERROR, 'Invalid payload', ...messages)
                   return
                }
   
               this.payload = payload;
               this.state = State.PayloadValidated;
               this.flow = getFlow(this.payload);
               }
            }           
        })
    }

    getPayloadFromIntent = (transactionIntentDetails : PaymentResponse.TransactionIntentResponse|undefined) : Payload | undefined=> {
        const payload : any ={}
        log(typeof transactionIntentDetails);
        if(!transactionIntentDetails)
        {
            this.throw(VALIDATION_ERROR, 'TransactionIntent Details not found')
            return
        }
        if(!this.sessionPayload)
        {
            //TODO: Move error handling logic to common methods to be used accross similar conditions.
            this.throw(VALIDATION_ERROR, 'Session Payload not found Details not found')
            return
        }
        payload.sourceSystem = transactionIntentDetails.transactionIntent.sourceSystem
        payload.preferredCultureCode = "en-US"
        payload.requestOrigin = RequestOrigin.WebSelfServe
        payload.sourceSystemContext = SourceSystemContext.Policy
        payload.sourceSystemIdentifier = transactionIntentDetails.transactionIntent.sourceSystemIdentifier
        payload.sourceSystemProgramIdentifier = transactionIntentDetails.transactionIntent.sourceSystemProgramIdentifier
        payload.token = this.sessionPayload.token
        if(transactionIntentDetails.transactionIntent.transactionPostingType == "TokenCreation")
        {
            payload.transactionType = TransactionType.Authorize
        }
        if(transactionIntentDetails.transactionIntent.transactionPostingType == "TokenCreationAuthorizeAndCapture" || transactionIntentDetails.transactionIntent.transactionPostingType == "AuthorizeAndCapture")
        {
            payload.transactionType = TransactionType.AuthorizeAndCapture
        }
        payload.currencyCode = transactionIntentDetails.transactionIntent.transactionAmount.currencyCode
        payload.amount = transactionIntentDetails.transactionIntent.transactionAmount.value
        payload.sourceSystemTransactionId = transactionIntentDetails.transactionIntent.sourceSystemCorrelationId
        return payload
    }

    fetchProfile = async () => {
        this.lastAction = 'fetchProfile'
        await this.logAction(async () => {
            if (!this.verifyStateIs(State.PayloadValidated))
                return
    
            if (!this.payload) {
                this.throw(FAILED, 'Payload is null')
                return
            }
        
            if (!this.payload.profileId) {
                this.throw(FAILED, 'Profile Id is null')
                return
            }
    
            const profile = await this.invokeApi((payload, profileApi) =>
                profileApi.getProfile({
                    sourceSystem: payload.sourceSystem,
                    sourceSystemIdentifier: payload.sourceSystemIdentifier,
                    sourceSystemContext: payload.sourceSystemContext,
                    profileId: payload.profileId!
                }))
            if (!profile)
                return
    
            if (profile.disabledAtUtc){
                this.throw(
                    VALIDATION_ERROR,
                    'Cannot edit a disabled profile',
                    `Profile '${profile.id}' was disabled on '${profile.disabledAtUtc}'.`)
                return
            }
            
            profile.paymentInstrument.paymentInstrumentType = pitFromString(
                profile.paymentInstrument.paymentInstrumentType)
            this.profile = profile
            this.state = State.ProfileLoaded
        })
    }

    fetchTransactionIntent = async () => {
        this.lastAction = 'fetchTransactionIntent'
        await this.logAction(async () => {
            
            const result = await this.invokePaymentsApi((sessionPayload, paymentsApi) => 
                paymentsApi.getTransactionIntent({
                    sourceSystem: sessionPayload.sourceSystem,
                    sourceSystemIdentifier: sessionPayload.sourceSystemIdentifier,
                    transactionIntentId: sessionPayload.transactionIntentId
                }));

            if (!result)
            return;
            if(result.transactionIntent.status.current.status == "Paid")
            {
                this.throw(
                    VALIDATION_ERROR,
                    'Cannot proceed with payment for the intent',
                    `TransactionIntent '${result.transactionIntent.id}' is already paid'.`)
                return
            }
            if(result.transactionIntent.status.current.status == "Cancelled")
            {
                this.throw(
                    VALIDATION_ERROR,
                    'Cannot proceed with payment for the intent',
                    `TransactionIntent '${result.transactionIntent.id}' is already cancelled'.`)
                return
            }
            this.transactionIntent = result;
            this.payload = this.getPayloadFromIntent(result) as Payload
        })
    }


    fetchPaymentOptions = async () => {
        this.lastAction = 'fetchPaymentOptions'
        await this.logAction(async () => {
            if (!this.verifyStateIs([State.PayloadValidated, State.ProfileLoaded]))
                return

            if (!this.payload || !this.sessionPayload){
                this.throw(FAILED, 'Payload is null')
                return
            }

            if (this.state == State.ProfileLoaded)
                this.sendEvent('GPMProfileEditStarted', undefined)

            const optionsResponse = await this.invokePaymentsApi((sessionPayload, paymentsApi) => 
                paymentsApi.getPaymentOptions({
                    sourceSystem: sessionPayload.sourceSystem,
                    sourceSystemIdentifier: sessionPayload.sourceSystemIdentifier,
                    transactionIntentId: sessionPayload.transactionIntentId
                }));

            if (!optionsResponse)
                return;

            console.log(optionsResponse);
            this.paymentOptions = optionsResponse.paymentOptions.options.map(p => {
                return <PaymentOption>{
                    paymentInstrumentType: pitFromString(p.paymentMethod),
                    vendor: p.transactionVendor
                }
            })            
            this.restoreState = this.state == State.ProfileLoaded
                ? State.ProfileLoaded
                : State.Start
            this.state = State.PaymentOptionsLoaded
            this.switchToCreateFlow()
        })
    }

    selectPaymentOption = async (paymentInstrumentType: PaymentInstrumentType) => {
        this.lastAction = 'selectPaymentOption'
        await this.logAction(async () => {
            if (!this.verifyStateIs([
                State.PaymentOptionsLoaded,
                State.PaymentOptionSelected,
                State.VendorDataLoaded]))
                return

            if (!this.paymentOptions) {
                this.throw(FAILED, 'Payment options is null')
                return
            }
            this.selectedPaymentOption = this.paymentOptions
                .find(p => p.paymentInstrumentType == paymentInstrumentType)
            if (!this.selectedPaymentOption){
                this.throw(FAILED, 'Payment instrument type is null')
                return
            }
            //if(this.selectedPaymentOption.paymentInstrumentType == PaymentInstrumentType.ApplePay)
            //{
                
            //}
            this.state = State.PaymentOptionSelected
        })
    }

    fetchPaymentOption = async () => {
        this.lastAction = 'fetchPaymentOption'
        await this.logAction(async () => {
            if (!this.verifyStateIs([State.PaymentOptionSelected, State.ProfileLoaded]))
                return

            if (!this.payload) {
                this.throw(FAILED, 'Payload is null')
                return
            }

            if (!this.selectedPaymentOption) {
                this.throw(FAILED, 'Selected Payment Option is null')
                return
            }
        
            const selected = this.selectedPaymentOption
            if(selected.vendor == "Paymentech")
            {
                this.vendorData = await this.invokePaymentsApi((sessionPayload, paymentsApi) => 
                paymentsApi.fetchVendorUIDetails({
                sourceSystem: sessionPayload.sourceSystem,
                sourceSystemIdentifier: sessionPayload.sourceSystemIdentifier,
                transactionId: sessionPayload.transactionIntentId,
                paymentInstrumentType:pitToString(selected.paymentInstrumentType),
                vendor: selected.vendor,
                requestOrigin: this.payload?.requestOrigin.toString()
                }));

                if (!this.vendorData)
                return

                debugLog('-- AppService: orderId =', this.vendorData.orderId)
            }
            this.state = State.VendorDataLoaded
        })
    }

    saveTransaction = async () => {
        let result: PaymentResponse.PaymentDetails | undefined = undefined
        try{
            
            const selectedPaymentOption = this.selectedPaymentOption;

            if(this.payload && selectedPaymentOption)  
            {
                result = await this.invokePaymentsApi((sessionPayload, paymentsApi) => 
                paymentsApi.savePayment({
                    sourceSystem: sessionPayload.sourceSystem,
                    sourceSystemIdentifier: sessionPayload.sourceSystemIdentifier,
                    TransactionId: sessionPayload.transactionIntentId,
                    TransactionVendor: selectedPaymentOption.vendor as string,
                    PaymentMethod: pitToString(selectedPaymentOption.paymentInstrumentType),
                    UId: this.vendorData?.uniqueIdentifier as string               
                }));             
                
            }                 
            
        }
        catch (error) {
            this.handleErrorResponse(error as AxiosError);
        }

        if(result?.isSuccessful)
        {
        const paymentDetails = result.savePaymentResponse;
        this.publishEvent('epmModuleWorkStatusEvent', paymentDetails);
        this.paymentSuccess= true;       
        } 
        else
        {
            this.throw(FAILED, 'SavePayment Request failed')
            return
        }
    }

    validateAppleMerchantSession = async (url: string) => {
        let result: any | undefined = undefined
        try{
            
            const selectedPaymentOption = this.selectedPaymentOption;

            if(this.payload && selectedPaymentOption)  
            {
                result = await this.invokePaymentsApi((sessionPayload, paymentsApi) => 
                paymentsApi.validateAppleMerchantSession({
                    sourceSystem: sessionPayload.sourceSystem,
                    sourceSystemIdentifier: sessionPayload.sourceSystemIdentifier,
                    transactionId: sessionPayload.transactionIntentId,
                    vendor: selectedPaymentOption.vendor as string,
                    validationUrl: url                                  
                }));             
                
            }                 
            
        }
        catch (error) {
            this.handleErrorResponse(error as AxiosError);
        }

        if(result?.isSuccessful)
        {
        return result.merchantSessionResponse;       
        } 
        else
        {
            this.throw(FAILED, 'Validation of Apple Pay Merchant Session Failed')
            return
        }
    }

    processApplePayPayment = async(paymentInstrument:ApplePayPaymentBundle, latitude:string, longitude:string) => {
        let result: ApplePayPaymentResponse | undefined = undefined
        try{
            
            const selectedPaymentOption = this.selectedPaymentOption;

            if(this.payload && selectedPaymentOption)  
            {
                result = await this.invokePaymentsApi((sessionPayload, paymentsApi) => 
                paymentsApi.ProcessApplePayPayment({
                    sourceSystem: sessionPayload.sourceSystem,
                    sourceSystemIdentifier: sessionPayload.sourceSystemIdentifier,
                    transactionId: sessionPayload.transactionIntentId,
                    vendor: selectedPaymentOption.vendor as string,
                    paymentBundle: paymentInstrument,
                    latitude: latitude,
                    longitude: longitude                                
                }));             
                
            }                 
            
        }
        catch (error) {
            this.handleErrorResponse(error as AxiosError);
        }

        if(result?.isSuccessful)
        {
            const paymentResponse = result;
            this.publishEvent('epmModuleWorkStatusEvent', paymentResponse);
            this.paymentSuccess= true; 
        } 
        else
        {
            this.throw(FAILED, 'Processing Apple Pay Payment Failed')
            return
        }
    }


    setVendorUIShown = async () => {
        this.lastAction = 'setVendorUIShown'
        await this.logAction(async () => {
            if (!this.verifyStateIs(State.VendorDataLoaded))
                return

            this.state = State.VendorFrameDisplayed
        })
    }

    setVendorUICancelled = async () => {
        this.lastAction = 'setVendorUICancelled'
        await this.logAction(async () => {
            if (!this.verifyStateIs(State.VendorFrameDisplayed))
                return
            
            this.selectedPaymentOption = undefined
            this.vendorData = undefined
            this.state = State.PaymentOptionsLoaded
        })
    }

    setVendorUIFailed = async (recoverable=false) => {
        this.lastAction = 'setVendorUIFailed'
        await this.logAction(async () => {
            if (!this.verifyStateIs(State.VendorFrameDisplayed))
                return
            
            if (recoverable)
                return

            this.selectedPaymentOption = undefined
            this.vendorData = undefined
            this.state = State.PaymentOptionsLoaded
        })
    }

    saveVendorProfile = async (data: string): Promise<void> => {
        this.lastAction = 'saveVendorPayload'
        await this.logAction(async () => {
            if (!this.verifyStateIs(State.VendorFrameDisplayed))
                return

            if (!this.payload) {
                this.throw(FAILED, 'Payload is null')
                return
            }

            if (!this.selectedPaymentOption) {
                this.throw(FAILED, 'Selected payment option is null')
                return
            }

            const request: SaveVendorProfileRequest = {
                requestOrigin: RequestOrigin[this.payload.requestOrigin],
                sourceSystemProgramIdentifier: this.payload.sourceSystemProgramIdentifier,
                paymentInstrumentType: pitToString(
                    this.selectedPaymentOption.paymentInstrumentType),
                vendor: '',//this.selectedPaymentOption.vendor,
                rawVendorResponse: data
            }

            this.profile = await this.invokeApi((payload, profileApi) => 
                profileApi.saveVendorProfile({
                    sourceSystem: payload.sourceSystem,
                    sourceSystemIdentifier: payload.sourceSystemIdentifier,
                    sourceSystemContext: payload.sourceSystemContext,
                    ...request
                }))

            if (!this.profile)
                return

            if (!this.isAuthorizeFlow) {
                this.sendEvent(200, this.profile)
                this.state = State.Completed
                return
            }
            
            this.state = State.ProfileLoaded
            
            //this.switchToEditFlow()

            //if (this.flow == Flow.EditProfileAuthorizeAndCapture)
            //   await this.captureReceivable()
        })
    }

    captureReceivable = async (): Promise<void> => {
        this.lastAction = 'captureReceivable'
        await this.logAction(async () => {
            if (!this.verifyStateIs(State.ProfileLoaded))
                return

            if (!this.isCaptureFlow){
                this.throw(
                    FAILED,
                    `Unexpected flow '${Flow[this.flow]}'`
                    )
                return
            }

            if (!this.payload) {
                this.throw(FAILED, 'Payload is null')
                return
            }

            if (!this.payload.sourceSystemTransactionId) {
                this.throw(FAILED, 'Source system transaction Id is null')
                return
            }

            if (!this.profile) {
                this.throw(FAILED, 'Profile is null')
                return
            }

            if (!this.payload.amount) {
                this.throw(FAILED, 'Amount is null')
                return
            }

            if (this.payload.amount <= 0) {
                this.throw(FAILED, 'Amount is less than 0')
                return
            }

            if (!this.payload.currencyCode) {
                this.throw(FAILED, 'Currency code is null')
                return
            }

            const request: Request.AuthorizeAndCapture = {
                sourceSystem: this.payload.sourceSystem,
                sourceSystemIdentifier: this.payload.sourceSystemIdentifier,
                sourceSystemProgramIdentifier: this.payload.sourceSystemProgramIdentifier,
                sourceSystemContext: this.payload.sourceSystemContext,
                profileId: this.profile.id,
                amount: {
                    currencyCode: this.payload.currencyCode,
                    value: this.payload.amount
                },
                sourceSystemTransactionId: this.payload.sourceSystemTransactionId
            }

            this.receivableCapture = await this.invokeApi((payload, profileApi) =>
                profileApi.authorizeAndCapture(request))

            if (!this.receivableCapture)
                return
            
            this.state = State.Completed
            this.sendEvent(200, this.receivableCapture)
        })
    }

    private async logAction<T>(fn: () => Promise<T>) {
        debugLog('-- AppService:', this.lastAction)

        const startState = this.state
        try{
            return await fn()
        }
        catch (err: any){
            log('    -- AppService: !!', err)
        }
        finally{
            debugLog(`    -- AppService: ${State[startState]} => ${State[this.state]}`)
        }
    }

    private async invokeApi<T>(
        fn: (payload: Payload, profileApi: ProfileApi) => Promise<T>
    ): Promise<T|undefined> {
        this.loading = true
        if (!this.payload) {
            this.throw(FAILED, "Payload is null")
            return
        }
        let result: T | undefined = undefined
        try{
            result = await fn(this.payload, this.getProfileApi(this.payload))
        }
        catch (error) {
            this.handleErrorResponse(error as AxiosError);
        }
        finally{
            this.loading = false
        }
        return result
    }

    private async invokePaymentsApi<T>(
        fn: (payload: SessionPayload, paymentsApi: PaymentsApi) => Promise<T>
    ): Promise<T|undefined> {
        this.loading = true
        if (!this.sessionPayload) {
            this.throw(FAILED, "Session Payload is null")
            return
        }
        let result: T | undefined = undefined
        try{
            result = await fn(this.sessionPayload, this.getPaymentsApi(this.sessionPayload.token))
        }
        catch (error) {
            this.handleErrorResponse(error as AxiosError);
        }
        finally{
            this.loading = false
        }
        return result
    }


    private getProfileApi(payload: Payload): ProfileApi {
        return new ProfileApi(
            this.subscriptionKey,
            payload.token,
            this.baseUri)
    }

    private getPaymentsApi(token: string): PaymentsApi {
        return new PaymentsApi(
            this.subscriptionKey,
            token,
            this.baseUri)
    }

    private switchToCreateFlow() {
        switch (this.payload?.transactionType){
            case TransactionType.Authorize:
                this.flow = Flow.CreateProfileAndAuthorize
                break

            case TransactionType.AuthorizeAndCapture:
                this.flow = Flow.CreateProfileAuthorizeAndCapture
                break
        }
    }

    private switchToEditFlow() {
       /*  switch (this.flow){
            case Flow.CreateProfile:
                this.flow = Flow.EditProfile
                break

            case Flow.CreateProfileAndAuthorize:
                this.flow = Flow.EditProfileAndAuthorize
                break

            case Flow.CreateProfileAuthorizeAndCapture:
                this.flow = Flow.EditProfileAuthorizeAndCapture
                break
        } */
    }

    private verifyStateIs = (state: State | State[]) => {
        const states = Array.isArray(state) ? state : [state]
        if (!states.includes(this.state)) {
            this.throw(
                FAILED,
                `Unexpected state '${State[this.state]}'`,
                `Expected state is '${states.map(s => State[s]).join(' or ')}'`)
            return false
        }
        return true
    }

    private handleErrorResponse = (reason: AxiosError) => {
        let statusCode = reason.response?.status ?? 500
        if (statusCode == 404)
            statusCode = 400

        const data: any = reason.response?.data ?? { errors: [] }
        const errors: string[] = data.errors && Array.isArray(data.errors)
            ? data.errors.map((e: any) => `Code: ${e.Code} - ${e.Message}`)
            : []
        const messages = [reason.message, ...errors]
        this.throw(statusCode, ...messages)
    }

    private throw = (statusCode: number, ...messages: string[]) => {
        this.sendEvent(statusCode, undefined, ...messages)
        this.reset()
        this.state = State.Failed
    }

    private sendEvent = (
        eventId: number | GpmEventType,
        resultPayload?: any,
        ...messages: string[]
    ) => {
        const type = typeof eventId == 'number'
            ? this.getEventType(eventId)
            : eventId
        const statusCode = typeof eventId == 'number'
            ? eventId
            : GpmEvents[eventId]
        const event: GpmEvent = {
            type,
            statusCode,
            messages,
            lastAction: this.lastAction,
            flow: Flow[this.flow] as keyof typeof Flow,
            inputPayload: this.payload
                ? {
                    ...this.payload,
                    sourceSystemDomain: 
                        SourceSystemContext[this.payload.sourceSystemContext],
                    requestOrigin: RequestOrigin[this.payload.requestOrigin],
                    transactionType: TransactionType[this.payload.transactionType]
                }
                : undefined,
            paymentOptions: this.paymentOptions?.map(p => ({
                ...p,
                paymentInstrumentType: pitToString(p.paymentInstrumentType)
            })),
            selectedPaymentOption: this.selectedPaymentOption
                ? {
                    ...this.selectedPaymentOption,
                    paymentInstrumentType: pitToString(
                        this.selectedPaymentOption?.paymentInstrumentType
                            ?? PaymentInstrumentType.None)
                }
                : undefined,
            resultPayload: resultPayload
                ? JSON.parse(JSON.stringify(resultPayload))
                : undefined
        }
        log('** Dispatching GPM Event', event)
        window.dispatchEvent(
            new CustomEvent<GpmEvent>(
                event.type as string,
                { detail: JSON.parse(JSON.stringify(event)) }))
    }

    private publishEvent=(
        eventName: string,
        inputPayload?: any)=>{            

            
            window.dispatchEvent(
                new CustomEvent<PaymentResponse.SavePaymentResponse>(
                    eventName,
                    { detail: JSON.parse(JSON.stringify(inputPayload)) }))
 
                    if (window.top?.opener){
                        window.top.opener.postMessage(inputPayload, '*');
                    }
                    else {
                        window.top?.postMessage(inputPayload, '*')                      
                    }                    
                }

    private getEventType = (statusCode: number): GpmEventType => {
        switch (statusCode){
            case 400:
                return 'GPMValidationFailed'

            case 401:
                return 'GPMApiAuthenticationFailed'

            case 200:
                return this.state == State.ProfileLoaded
                    ? 'GPMProfileEditStarted'
                    : this.isCaptureFlow
                        ? 'GPMReceivableCaptured'
                        : this.isAuthorizeFlow
                            ? 'GPMReceivableAuthorized'
                            : 'GPMProfileCreated'

            case 499:
                return this.isCaptureFlow
                    ? 'GPMReceivableCaptureCancelled'
                    : this.isAuthorizeFlow
                        ? 'GPMReceivableAuthorizeCancelled'
                        : 'GPMProfileCreationCancelled'

            default:
                return this.isCaptureFlow
                    ? 'GPMReceivableCaptureFailed'
                    : this.isAuthorizeFlow
                        ? 'GPMReceivableAuthorizeFailed'
                        : 'GPMProfileCreationFailed'
        }
    }
}


export const appService = new AppService()
export const viewModel = appService.vm