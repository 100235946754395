
export enum State {
    Start = 0,
    PayloadValidated = 1,
    PaymentOptionsLoaded = 2,
    PaymentOptionSelected = 3,
    VendorDataLoaded = 4,
    VendorFrameDisplayed = 5,
    VendorFrameCancelled = 6,
    VendorFrameCompleted = 7,
    VendorFrameFailed = 8,
    ProfileLoaded = 9,
    Completed = 10,
    Failed = 11
}
