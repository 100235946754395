import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c6ddb28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hosted-form" }
const _hoisted_2 = {
  key: 0,
  class: "errors"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.validationErrors.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validationErrors, (error) => {
            return (_openBlock(), _createElementBlock("div", {
              key: error,
              class: "error"
            }, _toDisplayString(error), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.vm.vendorData && _ctx.vm.vendorData.url)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 1,
          id: "secureFrame",
          name: "Chase Paymentech HPF",
          title: "Chase Paymentech HPF",
          class: "frame",
          src: _ctx.url
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}