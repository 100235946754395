
import { Component, Emit, Vue } from 'vue-facing-decorator'
import { viewModel } from '@/lib/AppService'
import { PaymentInstrumentType } from '@/lib/types'

type AccountField = {
    label: string,
    value: string
}

type Account = {
    icon: string,
    name: string,
    fields: AccountField[]
}

@Component
export default class ProfileView extends Vue {
    @Emit editProfile() {
        return "Edit Profile"
    }

    @Emit makePayment() {
        return "Make Payment"
    }

    bankAccountIcon = 'fa-solid fa-bank fa-fw'
    creditCardIcon = 'fa-solid fa-credit-card fa-fw'
    defaultIcon = 'fa-solid fa-money-bill-1 fa-fw'

    vm = viewModel
    account = { icon: '', name: '', fields: [] } as Account
    get showPay() { return this.vm.isAuthorizeFlow }

    mounted(){
        const payType = this.vm.profile?.paymentInstrument.paymentInstrumentType
            ?? PaymentInstrumentType.None
        if (payType == PaymentInstrumentType.BankAccount) {
            const acct = this.vm.profile?.paymentInstrument.bankAccountDetails
                ?? {
                    accountHolderName: 'Joe',
                    accountNumber: '????',
                    accountType: 'Account',
                    routingNumber: '',
                    consumerConsentReceived: false
                }

            this.account = {
                icon: this.bankAccountIcon,
                name: 'Bank Account',
                fields: [
                    {
                        label: 'Account Holder',
                        value: acct.accountHolderName
                    },
                    {
                        label: 'Account Number',
                        value: acct.accountNumber
                    },
                    {
                        label: 'Routing Number',
                        value: acct.routingNumber
                    },
                    {
                        label: 'Consent Received',
                        value: acct.consumerConsentReceived ? 'Yes' : 'No'
                    }
                ].filter(f => f.value)
            }
        }
        else if (payType == PaymentInstrumentType.CreditCard) {
            const acct = this.vm.profile?.paymentInstrument.cardDetails
                ?? {
                    accountHolderName: 'Joe',
                    accountNumber: '????',
                    brand: 'Card',
                    cvv: '111',
                    expiryYear: 2030,
                    expiryMonth: 12
                }

            this.account = {
                icon: this.creditCardIcon,
                name: acct.brand || 'Credit Card',
                fields: [
                    {
                        label: 'Name of Card Holder',
                        value: acct.accountHolderName
                    },
                    {
                        label: 'Card Number',
                        value: acct.accountNumber
                    },
                    {
                        label: 'CVV/CVC',
                        value: acct.cvv
                    },
                    {
                        label: 'Expiration Date',
                        value: `${acct.expiryMonth.toPrecision(2)}/${acct.expiryYear}`
                    }
                ]
            }
        }
    }
}
