
import { Component, Vue } from 'vue-facing-decorator'

import { State } from '@/lib/State'
import { viewModel  } from '@/lib/AppService'
import { getWindowPayload } from '@/lib/GpmPayload'
import { getLogger } from '@/lib/log'

import ProgressModal from '@/components/ProgressModal.vue'
import ProfileView from '@/components/ProfileView.vue'
import PaymentOptions from '@/components/PaymentOptions.vue'
import VendorFrame from '@/components/VendorFrame.vue'
import PaymentSuccess from './components/PaymentSuccess.vue'
import ApplePay from './components/ApplePay.vue'


const WIN_MESSAGE = 'message'
const GPM_RELOAD_MESSAGE = 'gpmReload'

const logger = getLogger(process.env.VUE_APP_ENABLE_DEBUG_LOGGING)
const log = logger.write
const debugLog = logger.debug

const logWindow = (prefix: string, win: Window | null | undefined) => {
    const { href, origin } = win?.location as Location
    debugLog(prefix, { href, origin })
}


@Component({
    components: { 
        ProgressModal,
        ProfileView,
        PaymentOptions,
        VendorFrame,
        PaymentSuccess,
        ApplePay
    }
})
export default class App extends Vue {
    vm = viewModel

    validationErrors: string[] = []

    get showProfileView() {
        return this.vm.state >= State.ProfileLoaded
            && this.vm.isEditFlow
            && this.vm.profile?.paymentInstrument
            && !this.vm.done
    }

    get showPaymentOptions() {
        return this.vm.state >= State.PaymentOptionsLoaded
            && this.vm.isCreateFlow
            && !this.vm.done
            && !this.vm.paymentSuccess
    }
    get showPaymentSuccess() {
        return this.vm.paymentSuccess            
    }

    get showVendorFrame() {
        return this.vm.state >= State.VendorDataLoaded
            && this.vm.state <= State.VendorFrameCancelled
            && !this.vm.done
            && !this.vm.paymentSuccess
    }

    get showApplePay() {
        return this.vm.isApplePaySelected  
        && !this.vm.done
        && !this.vm.paymentSuccess
    }

    async mounted() {
        debugLog('** Registering for vendor events...')
        logWindow('  ** window.top', window.top)
        logWindow('  ** window.self', window.self)
        logWindow('  ** window', window)

        window.removeEventListener(WIN_MESSAGE, this.handleVendorEvent)
        window.addEventListener(WIN_MESSAGE, this.handleVendorEvent)

        const existingScript = document.querySelector('#hpfScript')
        if(existingScript == null || existingScript == undefined)
        {
            const script = document.createElement('script')
            script.id = "hpfScript"
            script.src = `${this.vm.vendorOrigin}/includes/hpfParent.min.js`            
            const div = document.querySelector('#app')
            div?.appendChild(script)
        }

        if (window.top && (window.top != window)) {
            debugLog('** IFrame detected')
            if (window.top.origin != window.origin)
                throw new Error(
                    'Cross-origin iframes are not supported.\n'
                    + `Top window origin is '${window.top.origin}'.\n`
                    + `IFrame origin is '${window.origin}'.`)
            window.top.addEventListener(
                WIN_MESSAGE,
                evt => {
                    logWindow('** Forwarding vendor event to', window)
                    const event = JSON.parse(JSON.stringify(evt.data))
                    debugLog('  ** Event', evt)
                    debugLog('  ** Parsed event', event)
                    this.forwardMessage(event)
                })
        }
        else
            debugLog('** No IFrame detected')
        
        window.addEventListener(GPM_RELOAD_MESSAGE, this.onReload)
        await this.reload()
    }

    unmounted() {
        window.removeEventListener(WIN_MESSAGE, this.handleVendorEvent)
        if (window.top && (window.top != window.self)){
            debugLog('** IFrame detected, removing message forwarding...')
            window.top.removeEventListener(WIN_MESSAGE, this.forwardMessage)
        }
        window.removeEventListener(GPM_RELOAD_MESSAGE, this.reload)
        debugLog('** App UnMounted...')
    }

    forwardMessage(evt: any) {
        logWindow('** Forwarding event to inner window', window)
        logWindow('  ** Top window', window.top)
        debugLog('  ** Parsed event', evt)
        window.postMessage(evt)
    }

    async reload() {
        debugLog('-- App: reload')
        await this.vm.reset()         
        await this.vm.loadPayload()
        if (this.vm.state == State.PayloadValidated) {
            if (this.vm.isEditFlow)
                await this.vm.fetchProfile()
            else if (this.vm.isCreateFlow)
                await this.vm.fetchPaymentOptions()
        }
    }

    async onReload() {
        log(`** Received '${GPM_RELOAD_MESSAGE}' event:`, getWindowPayload())
        await this.reload()
    }

    async onEditProfile() {
        debugLog('-- App: onEditProfile')
        if (this.vm.state == State.ProfileLoaded)
            await this.vm.fetchPaymentOptions()
    }

    async makeProfilePayment() {
        debugLog('-- App: makeProfilePayment')
        if (this.vm.state == State.ProfileLoaded)
            await this.vm.captureReceivable()
    }

    async onPaymentSelected() {
        debugLog('-- App: onPaymentSelected')
        this.validationErrors = []
        if (this.vm.state == State.PaymentOptionSelected)
            await this.vm.fetchPaymentOption()
    }

    private async handleVendorEvent(event: any) {
        debugLog('>> Window message received:', event)

        if (event.origin != this.vm.vendorOrigin) {
            debugLog('-- App: window message was rejected because of an origin mismatch')
            debugLog('   Event origin', event.origin)
            debugLog('   Vendor origin', this.vm.vendorOrigin)
            return
        }

        if (!event.data || !event.data.subject)
            return
            
        const data = event.data
        const subject = data.subject
        log('-- App: Received vendor event:', subject, data)

        const isVendorEvent = [
            'init',
            'cancelPayment',
            'handleTransactionErrors',
            'completePayment'
        ].includes(subject)
        
        if (!isVendorEvent)
            return

        if (subject == 'init')
            await this.vm.setVendorUIShown()
        else if (subject == 'cancelPayment')
        {
            await this.vm.setVendorUICancelled()                      
        }            
        else if (subject == 'handleTransactionErrors') {
            let errors = await this.handleVendorFailed(data)
            errors = JSON.parse(JSON.stringify(errors))
            debugLog('    -- App: validation errors', errors)
            return errors
        }
        else if (subject == 'completePayment')
            //await this.saveVendorPayload(JSON.stringify(data, undefined, 2))
            await this.vm.saveTransaction()
    }

    private async saveVendorPayload(payload: string) {
        await this.vm.saveVendorProfile(payload)
    }

    private async handleVendorFailed(data: any){
        const content: any[] = Array.isArray(data.content)
            ? data.content
            : [data.content]

        this.validationErrors = []
        content.filter(c => !!c.field)
            .forEach(c => {
                const message = c.gateway_message || c.status_message
                this.validationErrors.push(message)
            })
        await this.vm.setVendorUIFailed(this.validationErrors.length > 0)
        return this.validationErrors
    }
}
