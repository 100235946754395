import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a2091052"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-headermessage" }
const _hoisted_3 = { class: "text-subheader" }
const _hoisted_4 = { class: "text-sm" }
const _hoisted_5 = { class: "text-amount" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled", "onClick"]
const _hoisted_8 = { class: "tab-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_ctx.paymentTabs.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.headerMessage), 1),
          _createElementVNode("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.linksMessage) + " ", 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.transactionamountvalue), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: "tab-row",
          disabled: _ctx.optionsDisabled
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentTabs, (tab) => {
            return (_openBlock(), _createElementBlock("div", {
              key: tab.type,
              class: _normalizeClass(tab.style),
              disabled: _ctx.optionsDisabled,
              onClick: ($event: any) => (_ctx.onPaymentSelected(tab.type))
            }, [
              _createVNode(_component_fa_icon, {
                icon: tab.icon,
                class: "tab-icon"
              }, null, 8, ["icon"]),
              _createElementVNode("div", _hoisted_8, _toDisplayString(tab.name), 1)
            ], 10, _hoisted_7))
          }), 128))
        ], 8, _hoisted_6)
      ]))
    : _createCommentVNode("", true)
}