import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressModal = _resolveComponent("ProgressModal")!
  const _component_PaymentOptions = _resolveComponent("PaymentOptions")!
  const _component_VendorFrame = _resolveComponent("VendorFrame")!
  const _component_ApplePay = _resolveComponent("ApplePay")!
  const _component_PaymentSuccess = _resolveComponent("PaymentSuccess")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.vm.loading)
      ? (_openBlock(), _createBlock(_component_ProgressModal, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.showPaymentOptions)
      ? (_openBlock(), _createBlock(_component_PaymentOptions, {
          key: 1,
          onPaymentOptionChanged: _ctx.onPaymentSelected
        }, null, 8, ["onPaymentOptionChanged"]))
      : _createCommentVNode("", true),
    (_ctx.showVendorFrame)
      ? (_openBlock(), _createBlock(_component_VendorFrame, {
          key: 2,
          validationErrors: []
        }))
      : _createCommentVNode("", true),
    (_ctx.showApplePay)
      ? (_openBlock(), _createBlock(_component_ApplePay, {
          key: 3,
          validationErrors: []
        }))
      : _createCommentVNode("", true),
    (_ctx.showPaymentSuccess)
      ? (_openBlock(), _createBlock(_component_PaymentSuccess, { key: 4 }))
      : _createCommentVNode("", true)
  ], 64))
}