
export const getLogger = (enableDebug = false) => ({
    write: (...data: any[]) => {
        console.log(...data)
    },

    debug: (...data: any[]) => {
        if (enableDebug)
            console.log(...data)
    }
})
